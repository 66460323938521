"use client";

import styles from "./ScrollBar.module.scss";
import React, { useEffect, useRef, useState } from "react";

export enum ScrollbarPosition {
  LEFT = "left",
  RIGHT = "right"
}

interface ScrollbarOptions {
  show?: boolean;
  position: ScrollbarPosition;
  offset?: string; // offset in percentage,
  width?: string;
  containerClassName?: string;
  indicatorClassName?: string;
}

interface ScrollbarProps {
  options: ScrollbarOptions;
  attachToRef: React.RefObject<HTMLDivElement>; // the ref of div it should attach to
  scrollRef: React.RefObject<HTMLDivElement>; // the ref of div being scrolled
}

const ScrollBar = ({ options, attachToRef, scrollRef }: ScrollbarProps) => {
  const scrollBarRef = useRef<HTMLDivElement | null>(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [scrollBarHeight, setScrollBarHeight] = useState(0);
  const [verticalPadding, setVerticalPadding] = useState(0);
  const [verticalMargin, setVerticalMargin] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (attachToRef.current && scrollRef.current && scrollBarRef.current) {
        const height = attachToRef.current.clientHeight;
        const scrollHeight = scrollRef.current.scrollHeight;
        const scrollTop = scrollRef.current.scrollTop;

        // Get the vertical padding
        const parentStyle = getComputedStyle(attachToRef.current);
        const computedVerticalPadding = parseFloat(parentStyle.paddingTop) + parseFloat(parentStyle.paddingBottom);
        setVerticalPadding(computedVerticalPadding);

        // Get the vertical margin
        const childStyle = getComputedStyle(scrollRef.current);
        const computedVerticalMargin = parseFloat(childStyle.marginTop) + parseFloat(childStyle.marginBottom);
        setVerticalMargin(computedVerticalMargin);

        const newScrollBarHeight = (height / scrollHeight) * 100;

        if (newScrollBarHeight > 100) {
          setScrollBarHeight(100);
          setScrollPercentage(0);
        } else {
          setScrollBarHeight(newScrollBarHeight);
          // Subtract verticalPadding and verticalMargin from height in the calculation of scrollPercentage
          setScrollPercentage(
            (scrollTop / (scrollHeight - (height - computedVerticalMargin - computedVerticalPadding))) *
              (100 - newScrollBarHeight)
          );
        }
      }
    };

    // create an observer instance with a callback function
    const observer = new MutationObserver(handleScroll);

    // start observing the scrollRef with configuration
    if (scrollRef.current) {
      observer.observe(scrollRef.current, { attributes: true, childList: true, subtree: true });
    }

    scrollRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll);
      observer.disconnect(); // stop observing when component unmounts
    };
  }, [attachToRef, scrollRef]);

  const offsetStyle = {
    [options.position === ScrollbarPosition.RIGHT ? "right" : "left"]: options.offset ? options.offset : "0",
    top: `${verticalPadding / 2}px`,
    height: `calc(100% - ${verticalPadding}px - ${verticalMargin}px)`,
    width: options.width ? options.width : "10px"
  };

  return (
    <div
      ref={scrollBarRef}
      className={`${options.show ? styles.show : styles.hide} ${
        options.containerClassName ? options.containerClassName : styles.scrollBarContainer
      }`}
      style={offsetStyle}
    >
      <div
        className={options.indicatorClassName ? options.indicatorClassName : styles.scrollIndicator}
        style={{
          height: `${scrollBarHeight}%`,
          top: `${scrollPercentage}%`,
          width: options.width ? options.width : "10px"
        }}
      ></div>
    </div>
  );
};

export default ScrollBar;
